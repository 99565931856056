import { getVideoSrcFromId, getVideoPosterSrcFromId, getNextVideo, getSubtitlesFromId } from "../utils/VideoService";
import styles from "../styles/VideoPlayer.module.css";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlay, faForwardStep } from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from "react-router-dom";

export const VideoPlayer = () => {
    const [paused, setPaused] = useState(false);
    const [controlsClassName, setControlsClassName] = useState(styles.slowHide);
    const [changeControls, setChangeControls] = useState(0);
    const { id } = useParams();
    const videoRef = useRef(null);
    const extraControlsRef = useRef(null);
    
    const hideControls = () => {
        var isPlaying = videoRef.current.currentTime > 0
            && !videoRef.current.paused
            && !videoRef.current.ended
            && videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

        if (!isPlaying) {
            setControlsClassName(styles.extraControls)
            return;
        }

        setControlsClassName(styles.hide)
    }

    const showControls = () => {
        var isPlaying = videoRef.current.currentTime > 0
            && !videoRef.current.paused
            && !videoRef.current.ended
            && videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

        if (!isPlaying) {
            extraControlsRef.current.className = styles.extraControls;
            setControlsClassName(styles.extraControls)
            return;
        }

        extraControlsRef.current.className = styles.slowHide;
        setControlsClassName(styles.slowHide)
        setChangeControls(prev => prev += 1);
    }

    const onPause = () => {
        setPaused(true);
        showControls()
    }

    const onPlay = () => {
        setPaused(false);
        hideControls();
    }

    return (
        <div className={styles.videoContainer}>
        <div>
            <div className={controlsClassName}
                key={changeControls}
                ref={extraControlsRef}>
                <Link className={styles.backButton} to="/">
                    <FontAwesomeIcon icon={faArrowLeft} style={{ zIndex: 101, color: "#FFFFFF", cursor: "pointer" }} />
                </Link>
                <Link className={styles.backButton} to={getNextVideo(id)}>
                    <FontAwesomeIcon icon={faForwardStep} style={{ zIndex: 101, color: "#FFFFFF", cursor: "pointer" }} />
                </Link>
            </div>
            <video
                crossOrigin="anonymous"
                id="resumeVideo"
                key={id}
                ref={videoRef}
                onPlay={onPlay}
                onPause={onPause}
                className={styles.videoPlayer}
                autoPlay muted disablePictureInPicture playsInline
                poster={getVideoPosterSrcFromId(id)}
                controlsList="nodownload noremoteplayback" controls>
                <source src={getVideoSrcFromId(id)} type="video/mp4" />
                <track src={getSubtitlesFromId(id)} label="English" kind="subtitles" srcLang="en"/>
            </video>
        </div>
    </div>
    )
}

export default VideoPlayer;