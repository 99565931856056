import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import VideoPlayer from './VideoPlayer';

class Main extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Routes>
                    <Route key={1} path='/' element={
                        <Home/>
                    } />
                    <Route key={2} path='/video/:id' element={
                        <VideoPlayer/>
                    } />
                </Routes>
            </>
        )
    }
}


export default Main