import React from 'react'
import styles from '../styles/MovieLineup.module.css'
import { getPortfolioItems } from '../utils/PortfolioItemService.js'
import PortfolioSlider from './PortfolioSlider';

const MovieLineup = () => {
  const items = getPortfolioItems();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>Projects</div>
          {items.projects && <PortfolioSlider items={items.projects}/>}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>Jobs</div>
          {items.jobs && <PortfolioSlider items={items.jobs}/>}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>Other</div>
        <div className={styles.images}>
          {items.other && <PortfolioSlider items={items.other}/>}
        </div>
      </div>
    </div>
  )
}

export default MovieLineup