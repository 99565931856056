import React, { useEffect, useState } from 'react'
import styles from '../styles/MovieLineup.module.css'
import PortfolioItemInfo from './PortfolioItemInfo'
import { useSearchParams } from 'react-router-dom'

const PortfolioItem = ({item, coverImage, index}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openItemInfo, setOpenItemInfo] = useState(searchParams.get("id") == item.movie)
  
  return (
      <>
          <PortfolioItemInfo 
            open={openItemInfo} 
            setOpen={setOpenItemInfo} 
            item={item} 
          />
          <div style={{transform: `translate(-${index*100}%)`}} className={styles.sliderItem}>
            <img  onClick={() => setOpenItemInfo(true)} className={styles.image} src={coverImage} /> 
          </div>
      </>
  )
}

export default PortfolioItem