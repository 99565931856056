import React, { useRef, useState } from 'react';
import styles from '../styles/intro.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeMute, faVolumeHigh, faPlay } from '@fortawesome/free-solid-svg-icons'
import { getVideoSrcFromId } from '../utils/VideoService';
import { Link } from 'react-router-dom';

export const CoverVideo = ({posterSrc, titleSrc, subTitle, movieId}) => {
  const [muteVideo, setMuteVideo] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [fadeout, setFadeout] = useState("false");
  const movieSrc = getVideoSrcFromId(movieId);
  const movieRef = useRef(null);

  const toggleMute = () => {
    setMuteVideo(prev => !prev);
  }

  const togglePlay = () => {
    var isPlaying = movieRef.current.currentTime > 0
      && !movieRef.current.paused
      && !movieRef.current.ended 
      && movieRef.current.readyState > movieRef.current.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      setMuteVideo(false);
      movieRef.current.play();
    } else if (muteVideo) {
      setMuteVideo(false);
    } else {
      setMuteVideo(true);
      movieRef.current.pause();
    }
  }

  const videoLoaded = () => {
    setIsVideoLoaded(true);
    setTimeout(() => {
      setFadeout("true");
    }, 2000);
  }

  return (
    <div className={styles.introContainer}>
      <div className={styles.introImageContainer} onClick={togglePlay}>
        <video ref={movieRef} onCanPlayThrough={() => videoLoaded()} poster={posterSrc} className={styles.introImage} autoPlay muted={muteVideo}>
            <source src={movieSrc} type="video/mp4"/>
        </video>
      </div>
      <div className={styles.contentWrapperCover} onClick={togglePlay}>
        <div className={styles.titleContainer}>
          <img className={styles.introTitle} data-fadeout={fadeout} src={titleSrc}></img>
          <div className={styles.introSubtitle} data-fadeout={fadeout}>{subTitle}</div>
          {isVideoLoaded
            ? <Link to={`video/${movieId}`} className={styles.playButtonCover}>Play <FontAwesomeIcon icon={faPlay} /></Link>
            : <Link to={`video/${movieId}`} onClick={(e) => e.preventDefault()} className={styles.playButtonDisabledCover}>Play <FontAwesomeIcon icon={faPlay} /></Link>
          }
        </div>
        <div className={styles.muteIcon} onClick={toggleMute}>
          {muteVideo ? <FontAwesomeIcon icon={faVolumeMute} /> : <FontAwesomeIcon icon={faVolumeHigh} />}
        </div>
      </div>
    </div>
  )
}

export default CoverVideo