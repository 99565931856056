import React, { useState } from 'react'
import styles from '../styles/MovieLineup.module.css'
import PortfolioItem from './PortfolioItem';
import useWindowDimensions from '../utils/useWindowDimensions';

const PortfolioSlider = ({items}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { height, width } = useWindowDimensions();

  const moveSliderUp = () => {
    if (currentIndex === items.length - 2 && width <= 868) {
        return;
    }
    setCurrentIndex(prev => prev + 1);
  }

  const moveSliderDown = () => {
    if (currentIndex === 0) {
        return;
    }
    setCurrentIndex(prev => prev - 1);
  }

    return (
      <>
        <div className={styles.slider}>
          <div className={styles.sliderItems}>
              {items.map((item) => <PortfolioItem key={item.movie} item={item} index={currentIndex} coverImage={item.coverImg}/>)}
          </div>
        {(items.length - currentIndex > 2 && width <= 868) &&
         <button data-direction="right" className={styles.sliderDirBtn} onClick={() => moveSliderUp()}></button>}
        {currentIndex > 0 &&
         <button data-direction="left" className={styles.sliderDirBtn} onClick={() => moveSliderDown()}></button>}
        </div>
      </>
    )
}

export default PortfolioSlider