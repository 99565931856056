import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/intro.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeMute, faVolumeHigh, faPlay } from '@fortawesome/free-solid-svg-icons'
import { Link, useSearchParams } from 'react-router-dom';
import { getVideoSrcFromId } from '../utils/VideoService';

export const Intro = () => {
  const [muteVideo, setMuteVideo] = useState(true);
  const [fadeout, setFadeout] = useState("false");
  const movieRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    var isPlaying = movieRef.current.currentTime > 0
      && !movieRef.current.paused
      && !movieRef.current.ended 
      && movieRef.current.readyState > movieRef.current.HAVE_CURRENT_DATA;

    if (searchParams.get("id") && isPlaying) {
      movieRef.current.pause();
    }
  }, [searchParams])
  

  const toggleMute = () => {
    setMuteVideo(prev => !prev);
  }

  const togglePlay = () => {
    var isPlaying = movieRef.current.currentTime > 0
      && !movieRef.current.paused
      && !movieRef.current.ended 
      && movieRef.current.readyState > movieRef.current.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      setMuteVideo(false);
      movieRef.current.play();
    } else if (muteVideo) {
      setMuteVideo(false);
    } else {
      setMuteVideo(true);
      movieRef.current.pause();
    }
  }

  const videoLoaded = () => {
    setIsVideoLoaded(true)

    setTimeout(() => {
      setFadeout("true");
    }, 2000);
  }

  return (
    <div className={styles.introContainer}>
      <div className={styles.introImageContainer} >
        <video ref={movieRef} onCanPlayThrough={() => videoLoaded()} poster='images/intro.png' className={styles.introImage} autoPlay muted={muteVideo}>
          <source src={getVideoSrcFromId("intro")} type="video/mp4"/>
        </video>
      </div>
      <div className={styles.contentWrapper} onClick={togglePlay}>
        <div className={styles.titleContainer}>
          <img className={styles.introTitle} data-fadeout={fadeout} src="images/intro-title.png"></img>
          <div className={styles.introSubtitle} data-fadeout={fadeout}>
              Software Engineer with 5 years of experience in technology and software development.
              Proven ability to quickly learn new tools and skills and apply them.
          </div>
          {isVideoLoaded
            ? <Link to={`video/intro`} className={styles.playButton}>Play &nbsp; <FontAwesomeIcon icon={faPlay} /></Link>
            : <Link to={`video/intro`} onClick={(e) => e.preventDefault()} className={styles.playButtonDisabled}>Play &nbsp; <FontAwesomeIcon icon={faPlay} /></Link>
          }
        </div>
        <div className={styles.muteIcon} data-fadeout={fadeout} onClick={toggleMute}>
          {muteVideo ? <FontAwesomeIcon icon={faVolumeMute} /> : <FontAwesomeIcon icon={faVolumeHigh} />}
        </div>
      </div>
    </div>
  )
}
