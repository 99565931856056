
export const getNextVideo = (id) => {
    let src = "";
    switch (id) {
        case "intro":
            src = "/video/jarvis";
            break;
        case "jarvis":
            src = "/video/nba";
            break;
        case "nba":
            src = "/video/searchforspeed";
            break;
        case "searchforspeed":
            src = "/video/aptys";
            break;
        case "aptys":
            src = "/video/weaver";
            break;
        case "weaver":
            src = "/video/siervocomp";
            break;
        case "siervocomp":
            src = "/video/tfc";
            break;
        case "tfc":
            src = "/video/skill";
            break;
        case "skill":
            src = "/";
            break;
        default:
            src = "/";
            break;
    }
    return src;

}

export const getVideoPosterSrcFromId = (id) => {
    let src = "";
    switch (id) {
        case "intro":
            src = "/images/intro.png";
            break;
        case "aptys":
            src = "/images/aptyssolutions.png";
            break;
        case "weaver":
            src = "/images/weaver.png";
            break;
        case "siervocomp":
            src = "/images/siervocomp.png";
            break;
        case "tfc":
            src = "/images/tfc.png";
            break;
        case "skill":
            src = "/images/skills.png";
            break;
        case "jarvis":
            src = "/images/jarvis.png";
            break;
        case "nba":
            src = "/images/nbatraderumor.png";
            break;
        case "searchforspeed":
            src = "/images/searchforspeed.png";
            break;
        default:
            break;
    }
    return src;
}

export const getSubtitlesFromId = (id) => {
    let src = "";
    switch (id) {
        case "intro":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/4th+Draft.vtt";
            break;
        case "aptys":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/APTYS.vtt";
            break;
        case "weaver":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/WEAVER.vtt";
            break;
        case "siervocomp":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/SIERVO_.vtt";
            break;
        case "tfc":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/TFC.vtt";
            break;
        case "skill":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/SKILLS.vtt";
            break;
        case "jarvis":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/JARVIS.vtt";
            break;
        case "nba":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/NBA+TR.vtt";
            break;
        case "searchforspeed":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/SPEED_.vtt";
            break;
        default:
            break;
    }
    return src;
}
export const getVideoSrcFromId = (id) => {
    let src = "";
    switch (id) {
        case "intro":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/4th+Draft.mov";
            break;
        case "aptys":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/APTYS.mov";
            break;
        case "weaver":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/WEAVER.mov";
            break;
        case "siervocomp":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/SIERVO_.mov";
            break;
        case "tfc":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/TFC.mov";
            break;
        case "skill":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/SKILLS.mov";
            break;
        case "jarvis":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/JARVIS.mov";
            break;
        case "nba":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/NBA+TR.mov";
            break;
        case "searchforspeed":
            src = "https://s3.amazonaws.com/www.jonathanjoransen.com/videos/SPEED_.mov";
            break;
        default:
            break;
    }
    return src;
}

