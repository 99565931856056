import React from 'react'
import { Intro } from '../components/Intro'
import MovieLineup from '../components/MovieLineup'

const Home = () => {
  return (
    <div>
        <Intro/>
        <MovieLineup/>
    </div>
  )
}

export default Home